import {dropLast, split} from 'ramda'
import {
  checkOrderable,
  checkValidInCart,
  convertDiscounts,
  getFieldValue,
  VIEWABLE_EXCLUDE_TYPES,
} from './product'
import {
  getValidColorHex,
  isString,
  parseJson,
  trimString,
} from '~/lib/utilities'
import { Product, ProductFacet, ProductLabel, ProductSortOption } from '~/types/product'

export function numberFormat(num: number, decimal?: number) {
  if (decimal) {
    return new Intl.NumberFormat("NL", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    }).format(num);
  } else {
    return new Intl.NumberFormat("NL").format(num);
  }
}

export const convertVariantProductsJson = (
  json: string,
  parentUrl?: string
): Product[] => {
  const variantProducts = parseJson(json)
  if (Array.isArray(variantProducts) && variantProducts.length) {
    const baseUrl = parentUrl
      ? dropLast(1, split('-')(parentUrl)).join('-')
      : ''
    return variantProducts
      .map((item) => convertVariantProduct(item, baseUrl))
      .sort((a, b) => {
        if (a.priceInclTax === b.priceInclTax) {
          return a.name > b.name ? 1 : -1
        }
        return a.priceInclTax - b.priceInclTax
      })
  }
  return []
}

// products
export const convertProduct = (data: any): Product => {
  const grossPrice = data.GrossPrice ? Number(data.GrossPrice) : 0
  const salePrice = data.SalePrice ? Number(data.SalePrice) : undefined
  const isVirtual =
    data.IsVirtualProduct !== 'False' || data.IsVirtualProductString !== 'False'
  const inStock = data.OutOfStock === 'False'
  // mock
  const stockQuantity = inStock ? 9999 : 0

  const type = data.ProductType

  const product: Product = {
    id: Number(data.ProductId),
    type,
    name: data.Title,
    img: data.ImageUrl,
    number: data.ProductCode,
    numberTrimmed: trimString(data.ProductCode, 6),
    url: data.Url,
    priceInclTax: salePrice ?? grossPrice,
    oldPriceInclTax:
      salePrice && grossPrice > salePrice ? grossPrice : undefined,
    isActive: true, // mock data
    // availability, mock now
    isViewable: !VIEWABLE_EXCLUDE_TYPES.has(type),
    isOrderable: true, // decide later
    isValidInCart: true, // decide later
    inStock: true,
    stockQuantity,
    banners: data.banners,
    maxStockEnabled: false,
    isDeliverable: true,
    isPickupable: true,
    isVirtual,
  }

  

  if(data.EENHEID) {
    product.contentType = data.EENHEID
  }

  if(data.INHOUD) {
    product.content = data.INHOUD
  }

  product.tags = []
  const trueValues = ["j", "1"];
  
  if(data.VEGANISTISCH && trueValues.includes(data.VEGANISTISCH) ) {
    product.tags.push({
      name: "Vegan",
      key: "VEGANISTISCH",
      order: 1
    })
  }

  if(data.VEGETARISCH && trueValues.includes(data.VEGETARISCH) ) {
    product.tags.push({
      name: "Vega",
      key: "VEGETARISCH",
      order: 1
    })
  }

  if(data.KEURMERKBIO && trueValues.includes(data.KEURMERKBIO) ) {
    product.tags.push({
      name: "Bio",
      key: "KEURMERKBIO",
      order: 1
    })
  }

  // Webshopbanner supported values
  product.containsAlcohol = false

  // some webshopbanner values should actually be a label, not banners. So... 
  const productLabels: ProductLabel[] = []
  const pushLabel = (label) => {
    productLabels.push({
      key: label
    })
  }

  if(data.webshopbanner){
   
    let banners = data.webshopbanner

    if(banners && banners.length){
      if(banners.includes('['))
        banners = JSON.parse(banners)

      if(typeof banners === 'object'){
        banners = Array.from(banners)
        banners.forEach((label) => {
          if(label.toLowerCase() === "alcoholhoudend") {
            product.containsAlcohol = true
            pushLabel(label)
          }

          // some webshopbanner values should actually be tags, not banners. So...
          if(label.toLowerCase() ==="nieuw") {
            product.tags?.push({
              name: "nieuw",
              key: "NIEUW",
              order: 1
            })
          }
        })
      } 
      else if(banners === "alcoholhoudend") {
        product.containsAlcohol = true
        pushLabel(banners)
      }
      
    }
    product.labels = productLabels
    
    // check if contains alcohol

   // product.containsAlcohol = banners.includes("alcoholhoudend")
    

    // some webshopbanner values should actually be tags, not banners. So...
    // if(banners.includes("nieuw")) {
    //   product.tags.push({
    //     name: "nieuw",
    //     key: "NIEUW",
    //     order: 1
    //   })
    // }
  }
  

  if (data.BrandName || data.BrandTitle || data.BrandUrl) {
    product.brand = {
      id: Number(data.BrandName),
      name: data.BrandTitle,
      url: data.BrandUrl,
    }
  }
  if (data.EANCode) {
    product.scanCode = data.EANCode
  }

  if (data.Size) {
    product.size = data.Size
  }

  if (data.Discounts) {
    const Discounts = isString(data.Discounts)
      ? parseJson(data.Discounts)
      : data.Discounts
    if (Array.isArray(Discounts) && Discounts.length) {
      product.discounts = convertDiscounts(Discounts)
    }
  }

  if (product.isVirtual && data.VariantProductsJson) {
    product.variants = convertVariantProductsJson(data.VariantProductsJson)
  }
  // Pricing
  if (data.Discount) {
    product.oldPriceInclTax = product.priceInclTax
    product.oldPriceExclTax = product.priceExclTax
    product.priceInclTax = data.Discount.PriceInclTax
    product.priceExclTax = data.Discount.PriceExclTax
  }

  if (product.priceInclTax) {
    // calculate from price integer an decimal incl tax
    product.priceIntegerInclTax = numberFormat(Math.floor(product.priceInclTax))
    const currentPriceInclTaxStr = (product.priceInclTax * 100).toFixed(0)
    product.priceDecimalInclTax = currentPriceInclTaxStr.substring(currentPriceInclTaxStr.length - 2)
  }

  if (product.priceExclTax) {
    // calculate from price integer an decimal incl tax
    product.priceIntegerExclTax = numberFormat(Math.floor(product.priceExclTax))
    const currentPriceExclTaxStr = (product.priceExclTax * 100).toFixed(0)
    product.priceDecimalExclTax = currentPriceExclTaxStr.substring(currentPriceExclTaxStr.length - 2)
  }

  if (product.oldPriceInclTax) {
    // calculate from price integer an decimal incl tax
    product.oldPriceIntegerInclTax = numberFormat(Math.floor(product.oldPriceInclTax))
    const currentOldPriceInclTaxStr = (product.oldPriceInclTax * 100).toFixed(0)
    product.oldPriceDecimalInclTax = currentOldPriceInclTaxStr.substring(currentOldPriceInclTaxStr.length - 2)
  }

  if (product.oldPriceExclTax) {
    // calculate from price integer an decimal incl tax
    product.oldPriceIntegerExclTax = numberFormat(Math.floor(product.oldPriceExclTax))
    const currentOldPriceIntegerExclTaxStr = (product.oldPriceExclTax * 100).toFixed(0)
    product.oldPriceDecimalExclTax = currentOldPriceIntegerExclTaxStr.substring(currentOldPriceIntegerExclTaxStr.length - 2)
  }

  // isOrderable
  product.isOrderable = checkOrderable(product)
  product.isValidInCart = checkValidInCart(product, false)

  // fields
  const Fields = data.FieldsJson && parseJson(data.FieldsJson)

  if (Fields && Array.isArray(Fields)) {
    const fields: Record<string, string> = {}
    Fields.forEach((field: any) => {
      fields[field.code.toLowerCase()] = field.value
    })
    const getValue = <T>(
      name: string,
      convertValue: (value: string) => T,
      defaultValue: T
    ) => {
      return getFieldValue(fields, {
        name,
        convertValue,
        defaultValue,
      })
    }
  
    // color
    const colorHex = getValue('Kleurcode', (v) => v, null)
    if (colorHex) {
      product.colorHex = getValidColorHex(colorHex)
    } else if (data.ColorHex) {
      product.colorHex = getValidColorHex(data.ColorHex)
    }
  }

  return product
}

export const convertProducts = (data: any[]): Product[] => {
  return data.map((item: any) => convertProduct(item))
}

export const convertVariantProduct = (data: any, baseUrl?: string): Product => {
  const product = convertProduct(data)
  if (!product.id) {
    product.id = Number(data.Name) // issue 4648: name = productId
  }
  if (!product.url && baseUrl && product.number) {
    product.url = `${baseUrl}-${product.number}`
  }
  product.variantType = data.ConnectionType
  return product
}

//  facets
export const convertFacet = (data: any): ProductFacet => {
  const facet = {
    field: data.Name,
    label: data.Title,
    values:
      data.Distribution?.map((item: any) => ({
        label: item.Key,
        value: item.Key,
        count: item.Value,
        checked: false,
      })) ?? [],
  }

  return facet
}

export const convertFacets = (data: any[]): ProductFacet[] => {
  return data.reduce((prev: ProductFacet[], current) => {
    if (current.Distribution?.length) {
      prev.push(convertFacet(current))
    }
    return prev
  }, [])
}

// sort option
export const convertSortOption = (data: any): ProductSortOption => {
  return {
    label: data.Title,
    field: data.Name,
    order: data.Order,
    value: `${data.Name}:${data.Order}`,
  }
}
